import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Sensor, SensorTypes, SensorEvent } from 'app/api2/sensor2';
import { Xid } from 'app/sensor/xid/xid';
import { environment } from 'environments/environment';
import { AuthService } from 'app/auth/auth.service';
import { Observable, of, forkJoin } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable()
export class ApiService {

    private header: HttpHeaders;
    private token: string;
    private devicesUrl: string;

    constructor(private http: HttpClient) {
    }

    setAuth(tokenType: string, token: string) {
        this.token = token;
        this.header = new HttpHeaders().set('Authorization', tokenType + ' ' + token);
        this.header.set('Accept', 'application/json');
    }

    setProject(project) {
        this.devicesUrl = environment.apiUrl + '/projects/' + project + '/devices';
    }

    streamByID(id: Xid): Observable<SensorEvent> {
        return this.streamSensors('device_ids=' + id.idString);
    }
    streamByIds(ids: Xid[]): Observable<SensorEvent> {
        let query = 'device_ids=' + ids[0].idString;
        ids.slice(1).forEach(id => {
            query += '&device_ids=' + id.idString;
        });
        return this.streamSensors(query);
    }
    streamAll(): Observable<SensorEvent> {
        return this.streamSensors();
    }

    getSensorsByType(type: SensorTypes): Observable<Sensor[]> {
        const param = new HttpParams().set('device_types', type.toString());
        return this.getSensors(param);
    }
    getAllSensors(): Observable<Sensor[]> {
        return this.getSensors();
    }
    getSensorById(id: Xid): Observable<Sensor> {
        const param = new HttpParams().set('device_ids', id.idString);
        return this.getSensors(param).pipe(map(s => s[0]));
    }

    getProjects(pageToken ?: string): Observable<any[]> {
        const pageTokenString = pageToken ? '&page_token=' + pageToken : '';
        return this.http.get(environment.apiUrl + '/projects?page_size=100' + pageTokenString, { headers: this.header }).pipe(mergeMap(data => {
            const projects = [];
            data['projects'].forEach(element => {
                const id = element.name.split('projects/')[1];
                projects.push({ id: id, name: element.displayName, org: element.organizationDisplayName });
            });

            if (data['nextPageToken'] !== '') {
                return forkJoin(of(projects), this.getProjects(data['nextPageToken'])).pipe(map((p) => {
                    return p[0].concat(p[1]);
                }))
            } else {
                return of(projects);
            }

        }))
    }

    private getSensors(params?: HttpParams): Observable<Sensor[]> {
        return this.http.get(this.devicesUrl, { headers: this.header, params: params }).pipe(map(data => {
            const sensors = [];
            data['devices'].forEach(element => {
                sensors.push(new Sensor(element));
            });
            return sensors;
        }))
    }
    private streamSensors(query?: string): Observable<SensorEvent> {
        let queryString = '';
        if (query) {
            queryString = '&' + query;
        }
        return Observable.create(observer => {
            const url = this.devicesUrl + ':stream?token=' + this.token + queryString;
            const eventSource = new EventSource(url);
            eventSource.onmessage = x => observer.next(JSON.parse(x.data));
            eventSource.onerror = e => observer.error(e);
            return () => {
                eventSource.close();
            };
        }).pipe(map(apiEvent => {
            return new SensorEvent(apiEvent['result'].event);
        }))
    }

}

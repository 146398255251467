import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';

import { ApiService } from 'app/api2/api.service';
import { SensorGroupService } from 'app/sensor-mapping/sensor-group/sensor-group.service';
import { SensorIdentificationService } from 'app/sensor/sensor-identification.service';

import { UseCaseSetup } from 'app/sensor-mapping/sensor-group/usecase-setup';
import { SensorGroup } from 'app/sensor-mapping/sensor-group/sensor-group';
import { Xid } from 'app/sensor/xid/xid';
import { SensorTypes } from 'app/api2/sensor2';

@Component({
    selector: 'app-mapping-dialog',
    templateUrl: './mapping-dialog.component.html',
    styleUrls: ['./mapping-dialog.component.css'],
    providers: [SensorIdentificationService],
})

// Component to be used in the New Group Dialog.
export class MappingDialogComponent implements OnInit {

    identifySubscription: Subscription;
    useCaseSetup: UseCaseSetup;
    sensorGroup: SensorGroup;
    selectedAlias: string;
    idInput: string;
    identifying: boolean = false;
    assetHeatmapInput: string;
    aliasAvailabilityMap = new Map<string, boolean>(); // Map to keep track of which aliases is taken.
    identifyTextMap = new Map<boolean, string>([[true, 'Cancel'], [false, 'Identify']]);
    identifyColorMap = new Map<boolean, string>([[true, 'warn'], [false, 'accent']]);

    constructor(private sensorGroupService: SensorGroupService,
        private sensorIdentificationService: SensorIdentificationService,
        private apiService: ApiService, private snackBar: MatSnackBar,
        private changeDetector: ChangeDetectorRef) {
    }

    ngOnInit() {

        if (!this.isAssetDetectionOrHeatmap()) {
            this.useCaseSetup.aliases.forEach(alias => {
                this.aliasAvailabilityMap.set(alias, true);
            });
            this.sensorGroup.sensors.forEach(sensor => {
                this.aliasAvailabilityMap.set(sensor.alias, false);
            });
        }
    }


    private openSnackBar(message: string) {
        this.snackBar.open(message, '', {
            duration: 2000,
        });
    }


    addSensorToGroup(id: string) {
        const sensorId = new Xid(id);
        this.apiService.getSensorById(sensorId).subscribe(
            sensor => {
                if (this.isAssetDetectionOrHeatmap()) { // Special case for Asset Deection and Heatmap, predefined aliases not required.
                    this.selectedAlias = this.assetHeatmapInput;
                }
                if (sensor) {
                    if (sensor.type === this.useCaseSetup.supportedSensorType.toString()) {
                        try {
                            this.sensorGroup.addSensor(sensorId, this.selectedAlias);
                            this.aliasAvailabilityMap.set(this.selectedAlias, false);
                            this.idInput = null;
                            this.selectedAlias = '';
                            this.assetHeatmapInput = '';
                        } catch (err) {
                            this.openSnackBar(err);
                        }
                    } else {
                        this.openSnackBar('Sensor type does not suit use case');
                    }
                } else {
                    this.openSnackBar('Sensor does not exist');
                }
            }
        );
    }

    deleteSensorFromGroup(id: string, alias: string) {
        this.sensorGroup.deleteSensor(new Xid(id));
        this.aliasAvailabilityMap.set(alias, true);
    }

    identify() {
        if (this.identifying) {
            this.identifySubscription.unsubscribe();
            this.openSnackBar('Looking for tapped sensors...');
        } else {
            // Call start method
            this.identifySubscription = this.sensorIdentificationService.identifySensor(this.useCaseSetup.supportedSensorType)
            .subscribe(
              sensor => {
                this.identifying = false;
                this.idInput = sensor.id.idString;
                this.changeDetector.detectChanges();
              },
              err => {
                this.identifying = false;
                this.openSnackBar('Could not find any tapped sensors');
              }
            );
        }
        this.identifying = !this.identifying;
    }

    isAssetDetectionOrHeatmap(): boolean {
        return this.useCaseSetup.useCase === 'Asset Detection' || this.useCaseSetup.useCase === 'Heatmap';
    }
    saveChanges() {
        this.sensorGroupService.updateGroup(this.sensorGroup);
    }
}

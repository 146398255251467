import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable, from, empty } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';


import { UserManager, UserManagerSettings, User } from 'oidc-client';
import { environment } from 'environments/environment';
import { ApiService } from 'app/api2/api.service';
import { SensorGroupService } from 'app/sensor-mapping/sensor-group/sensor-group.service';

@Injectable()
export class AuthService {

    public projectObservable: Observable<any>;
    private userRefURL: string;
    private oidClient = new UserManager(environment.oidcSettings);
    private oidUser: User = null;
    private oidUserObservable: Observable<User>;

    constructor(private afAuth: AngularFireAuth,
        private db: AngularFireDatabase,
        private apiService: ApiService,
        private sensorGroupService: SensorGroupService,
        private http: HttpClient) {

        this.oidUserObservable = from((this.oidClient.getUser()));
        this.oidUserObservable.subscribe(user => {
            if (user) {
                this.oidUser = user;
                this.apiService.setAuth(user.token_type, user.access_token);
            }
        });
        this.projectObservable = afAuth.authState.pipe(mergeMap(user => {
            if (user) {
                this.sensorGroupService.setUserID(user.uid);
                return this.db.object('users/' + user.uid + '/lastProject').valueChanges();
            } else {
                return empty();
            }
        }))
        this.projectObservable.subscribe(project => {
            this.apiService.setProject(project);
            this.sensorGroupService.setProject(project);
        });
    }

    isAuthDT(): Observable<boolean> {
        return this.oidUserObservable.pipe(map(oidUser => {
            if (this.oidUser != null && !this.oidUser.expired) {
                return true;
            } else {
                return false;
            }
        }))
    }

    getClaims(): any {
        return this.oidUser.profile;
    }

    startAuthentication(): Promise<void> {
        return this.oidClient.signinRedirect();
    }

    completeAuthentication(): Promise<void> {
        return this.oidClient.signinRedirectCallback().then(user => {
            if (user) {
                this.oidUser = user;
                this.apiService.setAuth(user.token_type, user.access_token);
                this.signInGoogle();
            }
        });
    }

    completeSignInSilent() {
        return this.oidClient.signinSilentCallback().catch(err => {
            console.log(err);
        });
    }
    signOut(): Promise<void> {
        this.afAuth.auth.signOut();
        return this.oidClient.signoutRedirect();
    }

    private signInGoogle() {
        let header = new HttpHeaders().set('Accept', 'application/json');
        header = header.set('Authorization', 'Bearer ' + this.oidUser.id_token);
        this.http.get(environment.getCustomTokenUrl, {headers: header} )
        .subscribe(data => {
            this.afAuth.auth.signInWithCustomToken(data['custom_token'])
            .then(() => console.log('Logged in to firebase'))
            .catch((error) => console.error('Firebase login failed'));
        });
    }
}

import { Injectable } from '@angular/core';

import { ApiService } from 'app/api2/api.service';
import { Xid } from 'app/sensor/xid/xid';
import { Sensor, SensorTypes } from 'app/api2/sensor2';
import { Observable } from 'rxjs';
import { take, filter, mergeMap, timeout} from 'rxjs/operators'

const MAX_WAIT = 10000; // Stop identifying after MAX_WAIT milliseconds without an event.

@Injectable()
export class SensorIdentificationService {
    constructor(private apiService: ApiService) {}

    // Listen to all sensors, and apply filter to get the correct events.
    // Use mergeMap to avoid returning an Observable of an Observable
    identifySensor(sensorType?: SensorTypes): Observable<Sensor> {
        return this.apiService.streamAll()
        .pipe(
            filter(event => ['touch', 'objectPresent'].indexOf(event.eventType) !== -1),
            mergeMap(event => {
            if (sensorType) {
                return this.apiService.getSensorById(event.sensorId).pipe(
                filter(sensor => sensor.type === sensorType.toString()))
            } else {
                return this.apiService.getSensorById(event.sensorId);
            }
            }),
            take(1),
            timeout(MAX_WAIT)
        )
    }
}

import { Component } from '@angular/core';
import { ApiService } from 'app/api2/api.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SensorGroupService } from 'app/sensor-mapping/sensor-group/sensor-group.service';

@Component({
    selector: 'app-project-select',
    templateUrl: './project-select.component.html',
    styleUrls: ['./project-select.component.css']
})
export class ProjectSelectComponent {
    formControl: FormControl;
    filteredProjects: Observable<any[]>;
    private projects = [];
    public currentProject;

    constructor(private sensorGroupService: SensorGroupService, private apiService: ApiService, private router: Router) {
        this.formControl = new FormControl();
        this.filteredProjects = this.formControl.valueChanges
            .pipe(
                startWith(''),
                map(project => project ? this.filterProjects(project) : this.projects.slice())
            );
        this.apiService.getProjects().subscribe(projects => {
            this.projects = projects;
            const tmp = projects.filter(project => project.id === this.sensorGroupService.projectID);
            if (tmp.length === 1) {
                this.currentProject = tmp[0];
            }
            this.formControl.updateValueAndValidity();
        });
    }

    filterProjects(name: string) {
        return this.projects.filter(project =>
            project.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
    }

    setProject(project: any) {
        this.sensorGroupService.storeProject(project.id);
        this.router.navigate(['/']);
    }
}

import { Component, ChangeDetectorRef, OnInit } from '@angular/core';

import { ApiService } from 'app/api2/api.service';

import { SensorGroup } from 'app/sensor-mapping/sensor-group/sensor-group';
import { SensorGroupService } from 'app/sensor-mapping/sensor-group/sensor-group.service';
import { SensorTypes } from 'app/api2/sensor2';
import { Xid } from 'app/sensor/xid/xid';
import { UseCase } from 'app/use-case';
import { SensorEvent } from 'app/api2/sensor2';
import { UseCaseSetup } from 'app/sensor-mapping/sensor-group/usecase-setup';

@Component({
  templateUrl: './rating-buttons.component.html',
  styleUrls: ['./rating-buttons.component.css'],
})
export class RatingButtonsComponent extends UseCase implements OnInit {

  public reactions = new Reactions;
  public chartTitleMap = new Map<string, string>([['PieChart', 'Pie Chart'], ['ColumnChart', 'Column Chart']]);

  public chartData = {
    chartType: 'PieChart',
    options: {
      colors: ['#22bb45', '#e0e000', '#dd0000']
    },
    dataTable: [
      ['Reactions', 'Votes'],
      ['Likes', 0],
      ['Neutrals', 0],
      ['Dislikes', 0]
    ]
  };

  constructor(apiService: ApiService, changeDetector: ChangeDetectorRef, sensorGroupService: SensorGroupService) {
   super(apiService, changeDetector, sensorGroupService);
  }

  ngOnInit() {
    // Google charts needs to be redrawn on a resize
    window.addEventListener('resize', (event) => {
      this.updateCharts();
    });
  }

  public resetUI() {
    this.sensorGroup.sensors.forEach(sensor => sensor.extra['reactions'] = 0);
    this.sensorGroupService.updateGroup(this.sensorGroup);
    this.reactions['Like Button'] = 0;
    this.reactions['Neutral Button'] = 0;
    this.reactions['Dislike Button'] = 0;
    this.updateCharts();
  }

  protected initializeUseCaseSetup(): UseCaseSetup {
    const aliases = [
      'Like Button',
      'Neutral Button',
      'Dislike Button'
    ];
    return new UseCaseSetup('Rating Buttons', SensorTypes.Touch, aliases);
  }

  protected handleReceivedSensorEvent(event: SensorEvent) {
    if (event.eventType === 'touch') {
      let pressedSensor = this.sensorGroup.sensors.find(sensor => sensor.sensorId.equalsTo(event.sensorId));
      pressedSensor.extra['reactions'] ++;
      this.reactions[pressedSensor.alias] = pressedSensor.extra['reactions'];
      this.sensorGroupService.updateGroup(this.sensorGroup);
      this.updateCharts();
    }
  }

  protected useMappingGroup() {
    this.reactions['Like Button'] = 0;
    this.reactions['Neutral Button'] = 0;
    this.reactions['Dislike Button'] = 0;
    this.sensorGroup.sensors.forEach( sensor => {
      if (sensor.extra['reactions']) {
        this.reactions[sensor.alias] = sensor.extra['reactions'];
      } else {
        sensor.extra['reactions'] = 0;
        this.reactions[sensor.alias] = 0;
      }
    })
    this.updateCharts();
  }

  protected handleNoGroup() {
    this.resetUI();
  }

  updateCharts(): void {
    // Force angular change detection by creating new object
    this.chartData = Object.create(this.chartData);
    this.chartData.dataTable[1][1] = this.reactions['Like Button'];
    this.chartData.dataTable[2][1] = this.reactions['Neutral Button'];
    this.chartData.dataTable[3][1] = this.reactions['Dislike Button'];
  }

  changeChartType(): void {
    this.updateCharts();
    if (this.chartData.chartType === 'ColumnChart') {
      this.chartData.chartType = 'PieChart';
      this.chartData.options.colors = ['#22bb45', '#e0e000', '#dd0000'];
    } else {
      this.chartData.chartType = 'ColumnChart';
      this.chartData.options.colors = ['#0000dd'];
    }
  }
}
class Reactions {
  'Like Button': number;
  'Neutral Button': number;
  'Dislike Button': number;
}

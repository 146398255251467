import { UseCase } from 'app/use-case';
import { Component, ChangeDetectorRef } from '@angular/core';

import { ApiService } from 'app/api2/api.service';

import { SensorTypes } from 'app/api2/sensor2';
import { SensorGroup } from 'app/sensor-mapping/sensor-group/sensor-group';
import { Xid } from 'app/sensor/xid/xid';
import { Item } from 'app/asset-detection/item/item';
import { SensorEvent } from 'app/api2/sensor2';
import { UseCaseSetup } from 'app/sensor-mapping/sensor-group/usecase-setup';

@Component({
    templateUrl: './asset-detection.component.html',
    styleUrls: ['./asset-detection.component.css', './asset-detection-items.css'],
})
export class AssetDetectionComponent extends UseCase {

    public items: Item[] = [];
    private colorMap = new Map<boolean, string>([[true, 'red'], [false, 'green']]);
    private idItemMap: Map<string, Item> = new Map();

    constructor(apiService: ApiService, changeDetector: ChangeDetectorRef) {
        super(apiService, changeDetector);
    }

    protected initializeUseCaseSetup(): UseCaseSetup {
        return new UseCaseSetup('Asset Detection', SensorTypes.Proximity, []);
    }

    protected handleReceivedSensorEvent(event: SensorEvent) {
        if (event.eventType === 'objectPresent') {
            this.idItemMap.get(event.sensorId.idString).updateStateOfItem(event.isObjectPresent);
        }
    }

    protected useMappingGroup() {
        this.items = [];
        for (const savedSensor of this.sensorGroup.sensors) {
            const newItem = new Item(savedSensor.alias);
            this.idItemMap.set(savedSensor.sensorId.idString, newItem);
            this.items.push(newItem);

            this.apiService.getSensorById(savedSensor.sensorId).subscribe( sensor => {
                this.idItemMap.get(sensor.id.idString).itemPresent = sensor.isObjectPresent;
            });
        }
    }

    protected handleNoGroup() {
        this.sensorGroup = null;
        this.items = [];
    }
}

import { Component, Input } from '@angular/core';

import { Room } from 'app/seat-occupancy/room';

// This room component will display a room with seats and a table, supports up to 4 chairs.
// The input should be bound to a Room object, in order for this component to get the required information.

@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
})
export class RoomComponent {
  @Input() room: Room;

  public tableColor = '#e1e1e1';
  public tableStroke = '#e1e1e1';
  private colorMap = new Map<boolean, string>([[true, '#F44336'], [false, '#4CAF50']]);

  constructor() { }

}

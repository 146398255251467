import { Injectable } from '@angular/core';
import { Router, CanActivateChild, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { map, take} from 'rxjs/operators'

import { AuthService } from 'app/auth/auth.service';
import { SensorGroupService } from 'app/sensor-mapping/sensor-group/sensor-group.service';

@Injectable()
export class AuthGuard implements CanActivateChild {

  constructor(private authService: AuthService, private router: Router) { }

  canActivateChild(): Observable<boolean> {
    return this.authService.isAuthDT().pipe(take(1), map(isAuth => {
      if (!isAuth) {
        this.authService.startAuthentication();
      }
      return isAuth;
    }))
  }
}

@Injectable()
export class ProjectGuard implements CanActivate {

  constructor(private sensorGroupService: SensorGroupService,
    private router: Router,
    private snackBar: MatSnackBar,
    private authService: AuthService) { }

  canActivate(): Observable<boolean> {
    if (this.sensorGroupService.projectID != null) {
      return of(true);
    } else {
      return this.authService.projectObservable.pipe(map(project => {
        if (project == null) {
          this.openSnackBar('Please select a project');
          this.router.navigate(['project-select']);
        }
        return project != null;
      }))
    }
  }

  private openSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 2000,
    });
  }
}

import { Xid } from 'app/sensor/xid/xid';
import { SavedSensor } from 'app/sensor-mapping/sensor-group/saved-sensor';

export class SensorGroup {

    useCase: string;
    sensors: SavedSensor[];

    constructor(useCase: string, sensors?: SavedSensor[]) {
        if (sensors) {
            this.sensors = sensors;
        } else {
            this.sensors = [];
        }
        this.useCase = useCase;
    }

    addSensor(sensorId: Xid, alias: string, extra?: any): void {
        for (const sensor of this.sensors) {
            if (sensor.sensorId.equalsTo(sensorId)) {
                throw (new Error('Sensor with id ' + sensorId.idString + ' has already been added to the group'));
            }
        }
        this.sensors.push(new SavedSensor(sensorId, alias, extra));
    }

    deleteSensor(sensorId: Xid): void {
        for (let i = 0; i < this.sensors.length; i++) {
            if (this.sensors[i].sensorId.equalsTo(sensorId)) {
                this.sensors.splice(i, 1);
            }
        }
    }

    getAliasById(xid: Xid): string {
        return this.sensors.find(sensor => xid.equalsTo(sensor.sensorId)).alias;
    }

    getXidList(): Xid[] {
        const xidList: Xid[] = [];
        for (const sensor of this.sensors) {
            xidList.push(sensor.sensorId);
        }
        return xidList;
    }

}

import { Xid } from 'app/sensor/xid/xid';

export class SavedSensor {

    sensorId: Xid;
    alias: string;
    // An extra attribute that can be used for anything
    extra: any;

    constructor(sensorId: Xid, alias: string, extra: any) {
        this.sensorId = sensorId;
        this.alias = alias;
        this.extra = extra ? extra : {};
    }
}

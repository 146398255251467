import { Component, ChangeDetectorRef } from '@angular/core';
import { trigger, style, transition, animate, keyframes } from '@angular/animations'; // Animations
import { MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';

import { ApiService } from 'app/api2/api.service';
import { SensorIdentificationService } from 'app/sensor/sensor-identification.service';
import { Sensor } from 'app/api2/sensor2';

const lightGrey = '#e1e1e1';
const activeColor = '#EF9A9A';

@Component({
  selector: 'app-sensor-installation',
  templateUrl: './sensor-installation.component.html',
  styleUrls: ['./sensor-installation.component.css'],
  animations: [
    trigger('sensorEvent', [
      transition('* => *', [
        animate('3s ease-in', keyframes([
          style({ backgroundColor: activeColor, offset: 0 }),
          style({ backgroundColor: lightGrey, offset: 0.5 })
        ]))
      ])
    ])
  ],
  providers: [SensorIdentificationService]
})
export class SensorInstallationComponent {

  partner = 'Partner';
  identifying = false;
  isInTimedMode = false;
  progressBarValue = 0;
  identifySubscription: Subscription;

  buttonTextMap = new Map<boolean, string>([[true, 'Cancel'], [false, 'Identify sensor']]);
  buttonColorMap = new Map<boolean, string>([[true, activeColor], [false, lightGrey]]);

  sensorList: Sensor[] = [];
  partnerIdMap = new Map<Sensor, number>();

  constructor(private apiService: ApiService,
    private snackBar: MatSnackBar,
    private sensorIdentificationService: SensorIdentificationService,
    private changeDetector: ChangeDetectorRef) {
  }

  toggleIdentification() {
    if (this.identifying) {
      this.identifySubscription.unsubscribe();
    } else {
      // Call start method
      this.identifySubscription = this.sensorIdentificationService.identifySensor()
      .subscribe(
        sensor => {
          this.identifying = false;
          try {
            this.addSensor(sensor);
          } catch (err) {
            this.openSnackBar(err);
          }
        },
        err => {
          this.identifying = false;
          this.openSnackBar('Could not find any tapped sensors');
        }
      );
    }
    this.identifying = !(this.identifying);
  }

  addSensor(newSensor: Sensor): void {
    for (const sensor of this.sensorList) {
      if (sensor.id.equalsTo(newSensor.id)) {
        throw (new Error('Sensor already added'));
      }
    }
    this.sensorList.push(newSensor);
    this.partnerIdMap.set(newSensor, this.getRandomId());
    this.changeDetector.detectChanges();
    this.apiService.streamByID(newSensor.id).subscribe(event => {
      for (const sensor of this.sensorList) {
        if (sensor.id.equalsTo(event.sensorId)) {
          sensor.updateTime = event.updateTime;
          sensor.updateWithEvent(event);
          this.changeDetector.detectChanges();
        }
      }
    });
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 2000,
    });
  }

  getRandomId(): number {
    let newId = (Math.floor(Math.random() * 1000000) + 1000000);
    for (const id of Array.from(this.partnerIdMap.values())) {
      if (newId === id) {
        newId = this.getRandomId();
      }
    }
    return newId;
  }
}

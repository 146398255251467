import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';

@Component({
  selector: 'app-silent-callback',
  templateUrl: './silent-callback.component.html'
})
export class SilentCallbackComponent implements OnInit {

  constructor( private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.authService.completeSignInSilent().catch(err => {
      console.error(err);
    });
  }

}

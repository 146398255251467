import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { MatDialog } from '@angular/material';
import { DisclaimerDialogComponent } from 'app/disclaimer-dialog/disclaimer-diaog.component';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html'
})
export class AuthCallbackComponent implements OnInit {

  constructor( private authService: AuthService, private router: Router, private dialog: MatDialog) { }

  ngOnInit() {
    this.authService.completeAuthentication().then(() => {
      this.router.navigate(['/']);
      this.openDialog();
    });
  }

  openDialog(): void {
      this.dialog.open(DisclaimerDialogComponent, {
      disableClose: true,
      minWidth: '20vw',
      maxWidth: '350px'
    });
  }

}

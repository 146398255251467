import { Component, ViewChild, ElementRef, OnInit, ChangeDetectorRef } from '@angular/core';
import { UseCaseSetup } from 'app/sensor-mapping/sensor-group/usecase-setup';
import { UseCase } from 'app/use-case';
import { ApiService } from 'app/api2/api.service';

import { SensorGroup } from 'app/sensor-mapping/sensor-group/sensor-group';
import { SensorTypes } from 'app/api2/sensor2';
import { Xid } from 'app/sensor/xid/xid';
import { SensorEvent } from 'app/api2/sensor2';
import { SavedSensor } from 'app/sensor-mapping/sensor-group/saved-sensor';
import { SensorGroupService } from 'app/sensor-mapping/sensor-group/sensor-group.service';

const intermediateCanvasWidth = 200;
const intermediateCanvasHeight = 125;
const canvasRatio = intermediateCanvasHeight / intermediateCanvasWidth;

@Component({
  selector: 'app-heatmap',
  templateUrl: './heatmap.component.html',
  styleUrls: ['./heatmap.component.css']
})

export class HeatmapComponent extends UseCase implements OnInit {
  dragIndex = -1;
  coldReference: number;
  hotReference: number;
  hotSliderLimits = [];
  coldSliderLimits = [];
  heatSensors: HeatSensor[] = [];
  sensoricon = new Image();
  canvasX: number;
  canvasY: number;
  intermediateCanvas: HTMLCanvasElement;
  intermediateCanvasCtx: CanvasRenderingContext2D;
  iconScale = 1; // Value used for scaling icons based on screen width.
  coordinateScale: number;
  devicePixelRatio: number;

  @ViewChild('heat') canvasRef: ElementRef;

  constructor(apiService: ApiService, changeDetector: ChangeDetectorRef, sensorGroupService: SensorGroupService) {
    super(apiService, changeDetector, sensorGroupService);

    // Create a temporary small canvas for drawing the heatmap.
    this.intermediateCanvas = document.createElement('canvas');
    this.intermediateCanvas.width = intermediateCanvasWidth;
    this.intermediateCanvas.height = intermediateCanvasHeight;
    this.intermediateCanvasCtx = this.intermediateCanvas.getContext('2d');
  }

  protected initializeUseCaseSetup(): UseCaseSetup {
    return new UseCaseSetup('Heatmap', SensorTypes.Temperature);
  }

  protected handleReceivedSensorEvent(event: SensorEvent) {
    const alias = this.sensorGroup.getAliasById(event.sensorId);
    for (let i = 0; i < this.heatSensors.length; i++) {
      if (this.heatSensors[i].alias === alias) {
        if (event.eventType === 'temperature') {
          this.heatSensors[i].temp = event.temperature;
          this.drawHeatMap();
        } else if (event.eventType === 'touch') {
          const scale = this.coordinateScale * this.devicePixelRatio;
          this.pulsate(this.heatSensors[i].xPos * scale, this.heatSensors[i].yPos * scale);
        }
        break;
      }
    }
  }

  protected useMappingGroup() {
    this.heatSensors = [];
    for (const sensor of this.sensorGroup.sensors) {
      this.apiService.getSensorById(sensor.sensorId).subscribe(s => {
        const xPos = sensor.extra.xPos ? sensor.extra.xPos : (Math.random() * intermediateCanvasWidth);
        const yPos = sensor.extra.yPos ? sensor.extra.yPos : (Math.random() * intermediateCanvasHeight);
        this.heatSensors.push({ alias: sensor.alias, temp: s.temperature, xPos: xPos, yPos: yPos });

        if (this.sensorGroup.sensors.length === this.heatSensors.length) {
          this.calculateScalesAndOffset();

          // Multiplying by 0.99 avoids dividing by zero in rangemap if all sensor temperatures are equal.
          this.coldReference = this.heatSensors[0].temp * 0.99;
          this.hotReference = this.heatSensors[0].temp;
          // Set hotReference and coldReference to the hottest and coldest sensors
          this.heatSensors.forEach(hs => {
            this.hotReference = this.hotReference < hs.temp ? hs.temp : this.hotReference;
            this.coldReference = this.coldReference > hs.temp ? hs.temp : this.coldReference;
          });
          this.hotSliderLimits = [(this.hotReference + this.coldReference) / 2, this.hotReference + 5];
          this.coldSliderLimits = [this.coldReference - 5, (this.hotReference + this.coldReference) / 2];
          this.drawHeatMap();
        }
      });
    }
  }

  protected handleNoGroup() {
    this.heatSensors = [];
  }

  ngOnInit() {
    this.devicePixelRatio = window.devicePixelRatio;
    this.sensoricon.src = '/assets/images/temperature-sensor.svg';
    this.sensoricon.onload = () => this.drawIcons();
    this.drawHeatMap();
    this.calculateScalesAndOffset();
    this.setEventListeners();
  }


  private drawHeatMap() {
    const imgData = this.intermediateCanvasCtx.getImageData(0, 0, intermediateCanvasWidth, intermediateCanvasHeight);
    const data = imgData.data;
    let index = 0;
    // Loop through every pixel in the canvas, calculate the pixel temperature as a weighted average.
    for (let x = 0; x < intermediateCanvasWidth; x++) {
      for (let y = 0; y < intermediateCanvasHeight; y++) {
        let pt, denominator = 0, numerator = 0;
        index = (y * intermediateCanvasWidth + x) * 4;
        for (let i = 0; i < this.heatSensors.length; i++) {
          const dist = this.distSquared(x, y, this.heatSensors[i].xPos, this.heatSensors[i].yPos);
          if (dist < 4) {
            pt = this.heatSensors[i].temp;
            break;
          }
          numerator += this.heatSensors[i].temp * (1 / dist);
          denominator += 1 / (dist);
        }
        pt = pt ? pt : (numerator / denominator);
        data[index] = this.rangeMap(pt, this.coldReference, this.hotReference, 0, 255);
        data[index + 1] = 130 - this.rangeMap(pt, this.coldReference, this.hotReference, 0, 130);
        data[index + 2] = 255 - this.rangeMap(pt, this.coldReference, this.hotReference, 0, 255);
        data[index + 3] = 255;
      }
    }

    /*  The heatmap is first drawn on a small canvas, and is then mapped over to the bigger drawn canvas.
        This helps with performance, and allows us to draw the icons on a proper scale */
    this.intermediateCanvasCtx.putImageData(imgData, 0, 0);
    const ctx = this.canvasRef.nativeElement.getContext('2d');
    ctx.drawImage(this.intermediateCanvas, 0, 0, this.canvasRef.nativeElement.width, this.canvasRef.nativeElement.height);
    this.drawIcons();
  }

  private rangeMap(num, in_min, in_max, out_min, out_max) {
    return (num - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
  }

  private drawIcons() {
    const ctx = this.canvasRef.nativeElement.getContext('2d');
    ctx.font = 16 * this.iconScale + 'px Arial';
    ctx.fillStyle = 'black';
    this.heatSensors.forEach(sensor => {
      const xPos = sensor.xPos * this.coordinateScale * this.devicePixelRatio;
      const yPos = sensor.yPos * this.coordinateScale * this.devicePixelRatio;
      ctx.drawImage(this.sensoricon, xPos - 30 * this.iconScale, yPos - 30 * this.iconScale, 60 * this.iconScale, 60 * this.iconScale);
      ctx.fillText(sensor.alias, xPos + 25 * this.iconScale, yPos - 7 * this.iconScale);
      ctx.fillText(sensor.temp.toFixed(2).toString() + ' °C', xPos + 25 * this.iconScale, yPos + 15 * this.iconScale);
    });
  }

  private pulsate(xPos: number, yPos: number, radius: number = 10) {
    const ctx = this.canvasRef.nativeElement.getContext('2d');
    const opacity = ((60 * this.iconScale / this.devicePixelRatio) - (radius)) / (60 * this.iconScale * this.devicePixelRatio);
    ctx.fillStyle = 'rgba(255,179,71,' + opacity + ')';
    ctx.beginPath();
    ctx.arc(xPos, yPos, radius, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.fill();
    if (radius < 60 * this.iconScale / this.devicePixelRatio) {
      setTimeout(() => {
        this.drawHeatMap();
        this.pulsate(xPos, yPos, radius + 2 * this.devicePixelRatio);
      }, 3);
    }
  }

  // We use distance squared for performance reasons.
  private distSquared(x1, y1, x2, y2) {
    return (x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1);
  }

  public changeHotReference(event: any) {
    this.hotReference = event.value;
    this.drawHeatMap();
  }

  public changeColdReference(event: any) {
    this.coldReference = event.value;
    this.drawHeatMap();
  }

  private calculateScalesAndOffset() {
    const rect = this.canvasRef.nativeElement.getBoundingClientRect();
    const tmpWidth = this.devicePixelRatio * (rect.right -  rect.left);
    this.canvasRef.nativeElement.width = Math.round(tmpWidth);
    this.canvasRef.nativeElement.height = Math.round(tmpWidth * canvasRatio);
    this.coordinateScale = (rect.right - rect.left) / intermediateCanvasWidth;
    this.canvasX = rect.left;
    this.canvasY = rect.top;
    // Linear functions does not really work on the icon scaling. Select one of three values:
    if (rect.right - rect.left < 600) {
      this.iconScale = 1 * this.devicePixelRatio;
    } else if (rect.right - rect.left < 1000) {
      this.iconScale = 1.4 * this.devicePixelRatio;
    } else {
      this.iconScale = 2 * this.devicePixelRatio;
    }
  }

  private setEventListeners() {
    // In the event listeners dragIndex refers to the sensor that is currently being moved around.

    // Loop through the sensors, and check if the click/touch was on a sensor.
    ['mousedown', 'touchstart'].forEach(eventType => {
      this.canvasRef.nativeElement.addEventListener(eventType, (event) => {
        this.calculateScalesAndOffset();
        const eventX = event.clientX ? event.clientX : event.changedTouches[0].clientX;
        const eventY = event.clientX ? event.clientY : event.changedTouches[0].clientY;
        const mouseX = (eventX - this.canvasX) / this.coordinateScale;
        const mouseY = (eventY - this.canvasY) / this.coordinateScale;
        for (let i = 0; i < this.heatSensors.length; i++) {
          const dist = this.distSquared(mouseX, mouseY, this.heatSensors[i].xPos, this.heatSensors[i].yPos);
          if (dist < 200) {
            this.dragIndex = i;
            break;
          }
        }
        this.drawHeatMap();
      });
    });

    // Update the sensor position and redraw the heatmap when moving sensors
    ['mousemove', 'touchmove'].forEach(eventType => {
      this.canvasRef.nativeElement.addEventListener(eventType, (event) => {
        if (this.dragIndex >= 0) {
          event.preventDefault(); // Prevent touchmove events from scrolling when the user moves a sensor
          const eventX = event.clientX ? event.clientX : event.changedTouches[0].clientX;
          const eventY = event.clientX ? event.clientY : event.changedTouches[0].clientY;
          const mouseX = (eventX - this.canvasX) / this.coordinateScale;
          const mouseY = (eventY - this.canvasY) / this.coordinateScale;
          if (mouseX > intermediateCanvasWidth || mouseY > intermediateCanvasHeight || mouseY < 0 || mouseX < 0) {
            this.dragIndex = -1;
            return;
          }
          this.heatSensors[this.dragIndex].xPos = mouseX;
          this.heatSensors[this.dragIndex].yPos = mouseY;
          this.drawHeatMap();
        }
      });
    });

    // When releasing the sensor, save the coordinates in the sensorgroup
    ['mouseup', 'touchend'].forEach(eventType => {
      this.canvasRef.nativeElement.addEventListener(eventType, (event) => {
        if (this.dragIndex >= 0 && this.sensorGroup) {
          for (let i = 0; i < this.heatSensors.length; i++) {
            if (this.heatSensors[this.dragIndex].alias === this.sensorGroup.sensors[i].alias) {
              this.sensorGroup.sensors[i].extra = {
                xPos: this.heatSensors[this.dragIndex].xPos,
                yPos: this.heatSensors[this.dragIndex].yPos
              };
              this.sensorGroupService.updateGroup(this.sensorGroup);
            }
          }
          this.dragIndex = -1;
        }
      });
    });

    window.addEventListener('resize', (event) => {
      this.calculateScalesAndOffset();
      this.drawHeatMap();
    });
  }
}

class HeatSensor {
  alias: string;
  temp: number;
  xPos: number;
  yPos: number;
}

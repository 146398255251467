import { UseCaseSetup } from 'app/sensor-mapping/sensor-group/usecase-setup';
import { Component, ChangeDetectorRef } from '@angular/core';

import { ApiService } from 'app/api2/api.service';

import { SensorGroup } from 'app/sensor-mapping/sensor-group/sensor-group';
import { SensorTypes } from 'app/api2/sensor2';
import { Xid } from 'app/sensor/xid/xid';
import { Room } from 'app/seat-occupancy/room';
import { UseCase } from 'app/use-case';
import { SensorEvent } from 'app/api2/sensor2';

@Component({
  templateUrl: './seat-occupancy.component.html',
})
export class SeatOccupancyComponent extends UseCase {

  room1: Room = new Room;
  room2: Room = new Room;

  constructor(apiService: ApiService, changeDetector: ChangeDetectorRef) {
    super(apiService, changeDetector);
  }

  protected initializeUseCaseSetup(): UseCaseSetup {
    const aliases = [
      'Chair North Room 1',
      'Chair South Room 1',
      'Chair East Room 1',
      'Chair West Room 1',
      'Chair North Room 2',
      'Chair South Room 2',
      'Chair East Room 2',
      'Chair West Room 2'
    ];
    return new UseCaseSetup('Seat Occupancy', SensorTypes.Proximity, aliases);
  }

  private setSeatState(alias: string, isOccupied: boolean) {
    // Find the seat number, and set the seat in the correct room.
    const seatPosition = alias.match(new RegExp(/Chair (.*) Room/))[1];
    if (alias.includes('Room 1')) {
      this.room1.setSeatState(seatPosition, isOccupied);
    } else if (alias.includes('Room 2')) {
      this.room2.setSeatState(seatPosition, isOccupied);
    }
  }

  private initRooms() {
    // Start with empty rooms and set them to the correct state.
    this.room1 = new Room;
    this.room2 = new Room;
    for (const savedSensor of this.sensorGroup.sensors) {
      this.apiService.getSensorById(savedSensor.sensorId).subscribe(s => {
        this.setSeatState(savedSensor.alias, s.isObjectPresent);
      });
    }
  }

  protected handleReceivedSensorEvent(event: SensorEvent) {
    if (event.eventType === 'objectPresent') {
        this.setSeatState(this.sensorGroup.getAliasById(event.sensorId), event.isObjectPresent);
    }
  }

  protected useMappingGroup() {
    this.initRooms();
  }

  protected handleNoGroup() {
    this.room1 = new Room();
    this.room2 = new Room();
  }
}

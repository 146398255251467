import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map, take} from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import * as firebase from 'firebase/app';
import { AuthService } from 'app/auth/auth.service';
import { SensorGroup } from 'app/sensor-mapping/sensor-group/sensor-group';
import { Xid } from 'app/sensor/xid/xid';

const FireBaseGroupRef = '/groups';

@Injectable()
export class SensorGroupService {

    public projectID: string;
    private groupRef: firebase.database.Reference;
    private groupUrl: string;
    private userID: string;

    constructor(private db: AngularFireDatabase) {
    }

    public setProject(project) {
        this.groupUrl = 'users/' + this.userID + '/projects/' + project + '/useCases/';
        this.projectID = project;
    }

    public setUserID(uid: string) {
        this.userID = uid;
    }

    public storeProject(project: string) {
        /*  TODO: Setting lastProject does not make sense in group-service, but i plan to
            make this service into a FireBaseService instead */
        this.db.database.ref('users/' + this.userID + '/lastProject').set(project);
    }

    public deleteGroup(group: SensorGroup): void {
        this.db.object(this.groupUrl + '/' + group.useCase).remove();
    }

    public updateGroup(currentGroup: SensorGroup): void {
        this.db.object(this.groupUrl + '/' + currentGroup.useCase).set(currentGroup);
    }

    public getUserGroup(useCase: string): Observable<SensorGroup> {
        return this.db.object(this.groupUrl + '/' + useCase).valueChanges().pipe(take(1), map(group => {
            return group ? this.fixGroup(group) : new SensorGroup(useCase);
        }))
    }

    private fixGroup(sensorGroupJson: any): SensorGroup {
        if (sensorGroupJson && sensorGroupJson.sensors) {
            const sensorGroup = new SensorGroup(sensorGroupJson.useCase);
            sensorGroupJson.sensors.forEach(sensor => {
                sensorGroup.addSensor(new Xid(sensor.sensorId._idString), sensor.alias, sensor.extra);
            });
            return sensorGroup;
        } else {
            return new SensorGroup(sensorGroupJson.useCase);
        }
    }

}

import { MatGridListModule, MatCardModule } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { ObservableMedia } from '@angular/flex-layout';
import { Observable, of } from 'rxjs';

@Component({
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {

  cols: Observable<number>;

  constructor(private observableMedia: ObservableMedia) { }

  ngOnInit() {
    // set cols
    if (this.observableMedia.isActive('xs')) {
      this.cols = of(1);
    } else if (this.observableMedia.isActive('sm') || this.observableMedia.isActive('md')) {
      this.cols = of(2);
    } else if (this.observableMedia.isActive('lg') || this.observableMedia.isActive('xl')) {
      this.cols = of(3);
    }

    // observe changes
    this.observableMedia.asObservable()
    .subscribe(change => {
      switch (change.mqAlias) {
        case 'xs':
          return this.cols = of(1);
        case 'sm':
        case 'md':
          return this.cols = of(2);
        case 'lg':
        case 'xl':
          return this.cols = of(3);
      }
    });
  }

}

// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Injectables
import { AuthGuard, ProjectGuard } from 'app/auth/route-guards';

// General components
import { DefaultLayoutComponent } from 'app/default-layout/default-layout.component';
import { LogoutComponent } from 'app/auth/logout/logout.component';
import { DashboardComponent } from 'app/dashboard/dashboard.component';
import { AuthCallbackComponent } from 'app/auth/auth-callback/auth-callback.component';
import { SilentCallbackComponent } from 'app/auth/silent-callback/silent-callback.component';
import { ProjectSelectComponent } from 'app/project-select/project-select.component';

// Use case components
import { SeatOccupancyComponent } from 'app/seat-occupancy/seat-occupancy.component';
import { RatingButtonsComponent } from 'app/rating-buttons/rating-buttons.component';
import { AssetDetectionComponent } from 'app/asset-detection/asset-detection.component';
import { SensorInstallationComponent } from 'app/sensor-installation/sensor-installation.component';
import { HeatmapComponent } from 'app/heatmap/heatmap.component';

const routes: Routes = [
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'auth/redirect',
    component: AuthCallbackComponent
  },
  {
    path: 'auth/silent-redirect',
    component: SilentCallbackComponent
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full',
      },
      {
        path: 'project-select',
        component: ProjectSelectComponent,
      },
      {
        path: 'rating-buttons',
        component: RatingButtonsComponent,
        canActivate: [ProjectGuard],
      },
      {
        path: 'seat-occupancy',
        component: SeatOccupancyComponent,
        canActivate: [ProjectGuard],
      },
      {
        path: 'asset-detection',
        component: AssetDetectionComponent,
        canActivate: [ProjectGuard],
      },
      {
        path: 'heatmap',
        component: HeatmapComponent,
        canActivate: [ProjectGuard],
      },
      {
        path: 'sensor-installation',
        component: SensorInstallationComponent,
        canActivate: [ProjectGuard],
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';

import { SensorGroupService } from 'app/sensor-mapping/sensor-group/sensor-group.service';
import { UseCaseSetup } from 'app/sensor-mapping/sensor-group/usecase-setup';
import { SensorGroup } from 'app/sensor-mapping/sensor-group/sensor-group';
import { MappingDialogComponent } from 'app/sensor-mapping/mapping-dialog/mapping-dialog.component';

@Component({
  selector: 'app-sensor-mapping',
  templateUrl: './sensor-mapping.component.html',
})
export class SensorMappingComponent implements OnInit {

  @Input() useCaseSetup: UseCaseSetup;
  @Output() sensorGroupEmitter: EventEmitter<SensorGroup> = new EventEmitter<SensorGroup>();
  sensorGroup: SensorGroup;

  constructor(private sensorGroupService: SensorGroupService, private dialog: MatDialog) { }

  private openGroupDialog() {
    const config = new MatDialogConfig();
    const dialogRef = this.dialog.open(MappingDialogComponent, config);
    dialogRef.componentInstance.useCaseSetup = this.useCaseSetup;
    dialogRef.componentInstance.sensorGroup = this.sensorGroup;
    dialogRef.afterClosed().subscribe(result => {
      this.sensorGroup = result;
      this.sensorGroupEmitter.emit(this.sensorGroup);
    });
  }


  ngOnInit() {
    this.tryLoadAndPromtUser();
  }

  trySetAndOpenDialog() {
    this.sensorGroupService.getUserGroup(this.useCaseSetup.useCase)
      .subscribe(group => {
        this.sensorGroup = group;
        this.openGroupDialog();
      });
  }

  // This function loads groups from the database, and does what seems best depending on the result.
  tryLoadAndPromtUser() {
    this.sensorGroupService.getUserGroup(this.useCaseSetup.useCase)
      .subscribe(group => {
        if (typeof group !== 'undefined' && group.sensors.length !== 0) {
          this.sensorGroup = group;
          this.sensorGroupEmitter.emit(group);
        } else {
          this.sensorGroup = new SensorGroup(this.useCaseSetup.useCase);
          this.openGroupDialog();
        }
      });
  }

}

import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from 'app/api2/api.service';

import { SensorGroup } from 'app/sensor-mapping/sensor-group/sensor-group';
import { UseCaseSetup } from 'app/sensor-mapping/sensor-group/usecase-setup';
import { Xid } from 'app/sensor/xid/xid';
import { SensorEvent } from 'app/api2/sensor2';
import { OnDestroy } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { SensorGroupService } from './sensor-mapping/sensor-group/sensor-group.service';


/*
This class is a parent class for use case components.
It forces a specific behaviour on these components. This behaviour includes:
    - Force the initialization of some necessary properties (UseCaseSetup)
    - How to use the mapping component
    - Set the received group from mapping to be the one in use.
    - When to subscribe and unsubscribe a group of sensors.
    - When to change things after receiving a sensor group.
    - When to change things when the group in use is deleted.
Use case components should extend the class and implement the abstract methods.
*/
export abstract class UseCase implements OnDestroy {

    protected sensorGroup: SensorGroup;
    public useCaseSetup: UseCaseSetup;
    protected sensorsSubscription: Subscription;
    private ngUnsubscribe = new Subject();

    constructor(protected apiService: ApiService, protected changeDetector: ChangeDetectorRef,
    protected sensorGroupService?: SensorGroupService) {
        this.useCaseSetup = this.initializeUseCaseSetup();
    }

    /*
        Method should be used to initialze useCaseSetup property that is necessary for mapping sensors.
        It returns an instance of UseCaseSetup class after setting the use case name,
        supperted sensor type and sensor aliases.
    */
    protected abstract initializeUseCaseSetup(): UseCaseSetup;
    /*
        Method enables dealing with sensor events when receiving one.
        E.g. use the sensor event to extract some useful information about
        the emitting sensor such as its id.
    */
    protected abstract handleReceivedSensorEvent(event: SensorEvent);
    /*
        Method allows to use the group we receive from the mapping component
        in order to reflect this on the component or the UI and apply desired changes.
    */
    protected abstract useMappingGroup();
    /*
        Method should handle the case when the current group is deleted
        E.g. resetting the UI or some fields in the use case components.
    */
    protected abstract handleNoGroup();

    /*
        Method uses the sensor service and perform subscription to sensors of the current group in use.
        It assigns the subscription to a property and calls handleReceivedSensorEventI(event) which is supposed
        to handle received events.
    */
    protected subscribeSensors() {
        this.sensorsSubscription = this.apiService.streamByIds(this.sensorGroup.getXidList())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(event => {
                // When receiving an event from one of the current group sensors, this method handles it
                this.handleReceivedSensorEvent(event);
                this.changeDetector.detectChanges();
            });
    }

    /*
        Method unsubscribes sensors of the last group used.
    */
    protected closePreviousSubscription() {
        if (this.sensorsSubscription) {
            this.sensorsSubscription.unsubscribe();
        }
    }

    /*
        Method is called by the mapping component in order to set the mapping result
        in the use case component. It specfies the behaviour that should be followed when receiving that result.
    */
    receiveMappingResult(resultingGroup: SensorGroup) {
        // Unsubscribe to the old subscription
        this.closePreviousSubscription();
        // Check if a group is received
        if (resultingGroup) {
            // Set received group as the current one
            this.sensorGroup = resultingGroup;
            // Use the current group
            this.useMappingGroup();
            // Subscribe sensors of the current group
            if (this.sensorGroup.sensors.length !== 0) {
                this.subscribeSensors();
            }
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}

export const environment = {
  appUrl: 'https://dt-demo-dev.firebaseapp.com/',
  apiUrl: 'https://api.disruptive-technologies.com/v2beta1',
  getCustomTokenUrl: 'https://us-central1-dt-demo-dev.cloudfunctions.net/getCustomToken',
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyABJ3u960auNd0whLKEapP2atdL_dikAk0',
    authDomain: 'dt-demo-dev.firebaseapp.com',
    databaseURL: 'https://dt-demo-dev.firebaseio.com',
    projectId: 'dt-demo-dev',
    storageBucket: 'dt-demo-dev.appspot.com',
    messagingSenderId: '881801246745'
  },
  oidcSettings: {
    authority: 'https://identity.disruptive-technologies.com/openid/',
    client_id: '215078',
    redirect_uri: `${window.location.origin}/auth/redirect`,
    response_type: 'id_token token',
    scope: 'openid email',
    automaticSilentRenew: true,
    silent_redirect_uri: `${window.location.origin}/auth/silent-redirect`,
    post_logout_redirect_uri: `${window.location.origin}/auth/redirect`
}
};



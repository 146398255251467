import { SensorTypes } from 'app/api2/sensor2';

export class UseCaseSetup {
    useCase: string;
    supportedSensorType: SensorTypes;
    aliases: string[];

    constructor(useCase: string, supportedSensorType?: SensorTypes, aliases?: string[]) {
        if (useCase) {
            this.useCase = useCase;
            this.supportedSensorType = supportedSensorType ? supportedSensorType : undefined;
            this.aliases = aliases ? aliases : [];
        } else {
            throw (new Error('Invalid use case name'));
        }
    }
}

// Error messages
const InvalidXidIdErrMsg = 'Id is invalid Xid';

export class Xid {

    private _idString: string;

    // Function supposed to validate if a string is in Xid, it should be implemented late.
    static isValidXid(id: string): boolean {
        if (!id) {
            return false;
        }
        return true;
    }

    constructor(id: string) {
        id = id.trim();
        if (Xid.isValidXid(id)) {
            this._idString = id;
        } else {
            throw new Error(InvalidXidIdErrMsg);
        }
    }

    get idString(): string {
        return this._idString;
    }

    equalsTo(xid: Xid): boolean {
        if (xid && this._idString === xid.idString) {
            return true;
        } else {
            return false;
        }
    }

}

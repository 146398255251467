import { Xid } from 'app/sensor/xid/xid';

export class Sensor {
    id: Xid;
    type: string;
    updateTime: Date;
    temperature?: number;
    isObjectPresent?: boolean;
    dateLastPressed: Date;
    project: string;  // TODO REMOVE OR FIX

    constructor(apiSensor: any) {
        this.type = apiSensor.type;
        this.project = apiSensor.name.split('devices/')[0];
        this.id = new Xid(apiSensor.name.split('devices/')[1]);
        this.updateTime = new Date(apiSensor.reported.networkStatus.updateTime); // TODO Is this really the correct time to keep track of? Which is the last pressed time?
        if (apiSensor.reported.temperature) {
            this.temperature = apiSensor.reported.temperature.value;
        }
        if (apiSensor.reported.objectPresent) {
            this.isObjectPresent = apiSensor.reported.objectPresent.state === 'PRESENT';
        }
    }
    public updateWithEvent(event: SensorEvent) {
        if (!this.id.equalsTo(event.sensorId)) {
            throw new Error('Event and Sensor does not have the same id');
        }
        this.updateTime = event.updateTime;
    }
}

export class SensorEvent {
    eventType: string;
    eventId: string;
    sensorId: Xid;
    updateTime: Date;
    temperature?: number;
    isObjectPresent?: boolean;
    constructor(apiEvent: any) {
        this.eventType = apiEvent.eventType;
        this.eventId = apiEvent.eventId;
        this.sensorId = new Xid(apiEvent.targetName.split('devices/')[1]);
        switch (this.eventType) {
            case 'touch':
                this.updateTime = new Date(apiEvent.data.touch.updateTime);
                break;
            case 'temperature':
                this.temperature = apiEvent.data.temperature.value;
                this.updateTime = new Date(apiEvent.data.temperature.updateTime);
                break;
            case 'objectPresent':
                this.isObjectPresent = apiEvent.data.objectPresent.state === 'PRESENT';
                this.updateTime = new Date(apiEvent.data.objectPresent.updateTime);
                break;
            case 'networkStatus':
                this.updateTime = new Date(apiEvent.data.networkStatus.updateTime);
                break;
            default:
                break;
        }
    }
}

export enum SensorTypes {
    Temperature = <any>'temperature',
    Touch = <any>'touch',
    Proximity = <any>'proximity'
}

export class Room {

    private stateSeatMap: Map<string, boolean> = new Map();

    setSeatState(seatPosition: string, isTaken: boolean) {
        this.stateSeatMap.set(seatPosition, isTaken);
    }

    getNrOccupied(): number {
        let nrOccupied = 0;
        this.stateSeatMap.forEach(taken => { if (taken) { nrOccupied++; } });
        return nrOccupied;
    }

    getNrSeats(): number {
        return this.stateSeatMap.size;
    }

    getSeatState(seatPosition: string) {
        return this.stateSeatMap.get(seatPosition);
    }

    isSeatDefined(seatPosition: string): boolean {
        return (typeof this.stateSeatMap.get(seatPosition)) !== 'undefined';
    }
}

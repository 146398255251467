export class Item {

    itemPresent: boolean;
    itemRemoved: boolean;
    name: string;

    constructor(name: string) {
        this.itemPresent = false;
        this.itemRemoved = false;
        this.name = name;
    }

    updateStateOfItem(itemPresent: boolean): void {
        this.itemPresent = itemPresent;
        if (this.itemPresent === false) {
            this.itemRemoved = true;
        }
    }

    dismissItem(): void {
        this.itemRemoved = false;
    }
}

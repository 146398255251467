// Angular
import { NgModule, ErrorHandler } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { environment } from 'environments/environment';

// Angular Material modules
import { DtDemoMaterialModule } from 'app/dt-demo-material.module';

// Injectables
import { GlobalErrorHandler } from 'app/global-error-handler';
import { AuthGuard, ProjectGuard } from 'app/auth/route-guards';
import { AppRoutingModule } from 'app/app-routing.module';
import { AuthService } from 'app/auth/auth.service';
import { ApiService } from 'app/api2/api.service';
import { SensorGroupService } from 'app/sensor-mapping/sensor-group/sensor-group.service';

// General components
import { AppComponent } from 'app/app.component';
import { DashboardComponent } from 'app/dashboard/dashboard.component';
import { LogoutComponent } from 'app/auth/logout/logout.component';
import { DefaultLayoutComponent } from 'app/default-layout/default-layout.component';
import { MappingDialogComponent } from 'app/sensor-mapping/mapping-dialog/mapping-dialog.component';
import { SensorMappingComponent } from 'app/sensor-mapping/sensor-mapping.component';
import { AuthCallbackComponent } from 'app/auth/auth-callback/auth-callback.component';
import { SilentCallbackComponent } from 'app/auth/silent-callback/silent-callback.component';
import { ProjectSelectComponent } from 'app/project-select/project-select.component';
import { DisclaimerDialogComponent } from 'app/disclaimer-dialog/disclaimer-diaog.component';


// Use case components
import { RatingButtonsComponent } from 'app/rating-buttons/rating-buttons.component';
import { SeatOccupancyComponent } from 'app/seat-occupancy/seat-occupancy.component';
import { AssetDetectionComponent } from 'app/asset-detection/asset-detection.component';
import { SensorInstallationComponent } from 'app/sensor-installation/sensor-installation.component';
import { RoomComponent } from 'app/seat-occupancy/room/room.component';
import { HeatmapComponent } from 'app/heatmap/heatmap.component';

@NgModule({
  declarations: [
    DashboardComponent,
    AppComponent,
    RatingButtonsComponent,
    SeatOccupancyComponent,
    AssetDetectionComponent,
    HeatmapComponent,
    LogoutComponent,
    DefaultLayoutComponent,
    MappingDialogComponent,
    SensorMappingComponent,
    SensorInstallationComponent,
    RoomComponent,
    AuthCallbackComponent,
    SilentCallbackComponent,
    ProjectSelectComponent,
    DisclaimerDialogComponent,
  ],
  entryComponents: [MappingDialogComponent, DisclaimerDialogComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    FlexLayoutModule,
    AngularFireModule.initializeApp(environment.firebaseConfig, 'dt-demo'),
    DtDemoMaterialModule,
    AngularFireModule.initializeApp(environment.firebaseConfig, 'dt-demo-dev'),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    Ng2GoogleChartsModule,
    ReactiveFormsModule,
  ],
  providers: [ApiService, AuthService, AuthGuard, ProjectGuard, SensorGroupService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

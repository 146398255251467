import { Component } from '@angular/core';

@Component({
    selector: 'app-disclaimer-dialog',
    templateUrl: './disclaimer-dialog.component.html',
    styleUrls: ['./disclaimer-dialog.component.css'],
})

export class DisclaimerDialogComponent {

}
